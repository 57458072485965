<template>
  <div>
    <h2 class="display-2 mb-6 text-center white--text">Benefits</h2>
    <v-row class="white--text">
      <v-col cols="12" md="6" class="px-0" v-for="b in benefits" :key="b.i">
        <v-list color="transparent" dark>
          <v-list-item class="px-0 text-center">
            <div class="mx-auto d-flex align-center">
              <v-icon x-large color="primary" class="mr-3">{{ b.icon }}</v-icon>
              <p class="headline mb-0">
                {{ b.title }}
              </p>
            </div>
          </v-list-item>
        </v-list>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      benefits: [
        {
          icon: "mdi-piggy-bank-outline",
          title: "401k Match",
        },
        {
          icon: "mdi-account-hard-hat-outline",
          title: "Job Training",
        },
        {
          icon: "mdi-heart-outline",
          title: "Health Care",
        },
        {
          icon: "mdi-tooth-outline",
          title: "Dental Insurace",
        },
        {
          icon: "mdi-eye-outline",
          title: "Vision Insurace",
        },
        {
          icon: "mdi-beach",
          title: "Paid Vacation",
        },
        // {
        //   icon: "",
        //   title: "",
        //   subtitle: "",
        // },
      ],
    };
  },
};
</script>

<style lang="scss" scoped></style>
