<template>
  <div class="mt-16">
    <v-img
      :height="quote.height"
      :src="
        $vuetify.breakpoint.smAndDown
          ? 'https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727724/Heros/CareersTopPhoto_psia7g.webp'
          : 'https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_1600/v1655727724/Heros/CareersTopPhoto_psia7g.webp'
      "
      class="text-center"
    >
      <v-overlay absolute opacity=".3" color="black">
        <h1 class="display-2 mx-auto mb-7">{{ quote.title }}</h1>
        <v-btn href="#positions" color="primary" rounded class="mt-4">
          View Positions
        </v-btn>
      </v-overlay>
    </v-img>
    <v-row
      justify="center"
      :class="$vuetify.breakpoint.smAndDown ? 'px-4' : 'px-16'"
    >
      <v-col cols="12" md="10">
        <stepper />
        <carousel />
        <benefits class="pt-10 mx-auto" />
        <v-row class="my-16" id="positions" justify="center">
          <v-col class="white--text">
            <h2 class="display-2 mb-6 text-center">Positions</h2>
            <h2 class="font-weight-light primary--text">
              JLR Contractors positions
            </h2>
            <career-card
              :careers="positions.filter((e) => e.company == 'JLR Contractors')"
            />
            <v-divider dark class="my-3" />
            <h2 class="font-weight-light primary--text">
              JLR Woodwork positions
            </h2>
            <career-card
              :careers="positions.filter((e) => e.company == 'JLR Woodwork')"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { positionsCollection } from "../firebase/credentials";
import CareerCard from "@/components/Careers/CareerCard";
import Benefits from "../components/Page/Benefits";
import Stepper from "../components/Page/Stepper.vue";
import Carousel from "../components/Page/Carousel.vue";
export default {
  components: {
    CareerCard,
    Benefits,
    Stepper,
    Carousel,
  },
  data() {
    return {
      positions: [],
      quote: {
        title: "Join a team making Utah a better place to live.",
        button: {
          text: "View Positions",
          to: "/careers/#positions",
        },
        height: 600,
      },
    };
  },
  async mounted() {
    let result = await positionsCollection;

    result.onSnapshot((doc) => {
      doc.docChanges().forEach(async (p) => {
        if (p.type === "added") {
          let obj = p.doc.data();
          obj.id = p.doc.id;
          obj.show = false;
          this.positions.push(obj);
        } else if (p.type === "modified") {
          let id = p.doc.id;
          let index = this.positions.map((obj) => obj.id).indexOf(id);
          this.positions[index].domain = p.doc.data().domain;
        } else if (p.type === "removed") {
          let id = p.doc.id;
          let index = this.positions.map((obj) => obj.id).indexOf(id);
          this.positions.splice(index, 1);
        }
      });
    });
  },
};
</script>

<style lang="scss" scoped>
h1 {
  max-width: 700px;
}
</style>
