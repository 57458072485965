<template>
  <div class="my-16">
    <v-row justify="center">
      <v-col cols="12" md="10" lg="8" xl="7" class="text-center white--text">
        <h2 class="display-1 mb-3">Why JLR Contractors</h2>
        <p v-for="d in description" :key="d.i">
          {{ d }}
        </p>
        <h4 class="title">OUR HIRING PROCESS</h4>
      </v-col>
    </v-row>
    <v-row justify="center" class="line">
      <hr v-if="$vuetify.breakpoint.mdAndUp" />
      <v-col
        class="d-flex"
        :class="
          $vuetify.breakpoint.smAndDown
            ? 'justify-start align-baseline'
            : 'flex-column align-center justify-start'
        "
        cols="12"
        md="3"
        v-for="s in stepper"
        :key="s.i"
      >
        <h3
          class="title step white--text"
          :class="$vuetify.breakpoint.smAndDown ? 'mr-3' : ''"
        >
          {{ s.step }}
        </h3>
        <h3
          class="title font-weight-light white--text mt-3"
          :class="$vuetify.breakpoint.smAndDown ? '' : 'text-center'"
        >
          {{ s.text }}
        </h3>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      stepper: [
        {
          step: 1,
          text: "Apply Online.",
        },
        {
          step: 2,
          text: `We'll review your application.`,
        },
        {
          step: 3,
          text: `If it seems like a fit, we'll set up an interview with you.`,
        },
        {
          step: 4,
          text: `We'll let you know.`,
        },
      ],
      description: [
        `We have opportunities available for go-getters with a positive attitude
        and a willingness to learn and share ideas. We’re looking for
        individuals who are driven to do their best and who will always do
        what’s right. We want team players who are passionate about making a
        difference in the construction industry.`,
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.step {
  border: 2px solid var(--v-primary-base);
  background: var(--v-secondary-base);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 30px;
  line-height: 0px;
  display: flex;
  justify-content: center;
  align-content: center;
  z-index: 1;
}

.line {
  position: relative;
  hr {
    position: absolute;
    width: 75%;
    top: 45px;
    z-index: 0;
  }
}
</style>
