<template>
  <vueper-slides
    class="no-shadow"
    :visible-slides="3"
    :gap="2"
    :slide-ratio="1 / 2"
    transition-speed="800"
    :infinite="true"
    :dragging-distance="300"
    :autoplay="true"
    :bullets="false"
  >
    <vueper-slide
      v-for="(img, i) in images"
      :key="i.i"
      :image="img"
    />
  </vueper-slides>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      images: [
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727843/Lifestyle/CareersSlider20_pnus31.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727803/Lifestyle/CareersSlider15_tij8lw.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727835/Lifestyle/CareersSlider24_ovvozb.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727836/Lifestyle/CareersSlider18_nwphtt.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727839/Lifestyle/CareersSlider11_p22jo9.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727829/Lifestyle/Filler_Background02_yxp8pq.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727826/Lifestyle/CareersSlider0_zsdrhr.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727825/Lifestyle/CareersSlider7_lmmoeu.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727823/Lifestyle/CareersSlider19_v1gflv.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727821/Lifestyle/CareersSlider10_qyzbv1.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727816/Lifestyle/CareersSlider9_bqm0cm.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727813/Lifestyle/CareersSlider17_fwqveb.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727812/Lifestyle/CareersSlider5_or6h86.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727807/Lifestyle/CareersSlider4_tw7jne.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727802/Lifestyle/CareersSlider3_dg2qhe.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727798/Lifestyle/CareersSlider16_brrnaw.webp",
        "https://res.cloudinary.com/jlr-contractors/image/upload/c_scale,w_600/v1655727792/Lifestyle/CareersSlider12_ywu5ci.webp",
      ]
    }
  },
};
</script>

<style lang="scss" scoped></style>
