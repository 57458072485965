<template>
  <div>
    <v-card
      v-for="p in careers"
      :key="p.i"
      dark
      color="grey darken-3"
      class="mx-auto mb-5"
    >
      <v-card-title>
        <v-btn
          v-if="isAuthenticated"
          :to="`/career-edit/${p.id}`"
          title="Edit Position"
          color="primary"
          class="mr-2"
          outlined
          rounded
          :absolute="$vuetify.breakpoint.smAndDown"
          :bottom="$vuetify.breakpoint.smAndDown"
        >
          Edit
          <v-icon right> mdi-pencil </v-icon>
        </v-btn>
        <v-icon
          v-if="isAuthenticated"
          class="mr-2"
          color="error"
          @click="remove(p.id)"
        >
          mdi-delete
        </v-icon>
        {{ p.position }}
      </v-card-title>
      <v-card-subtitle v-if="p.location">
        <v-icon>mdi-map-marker</v-icon>
        {{ p.location }}
      </v-card-subtitle>

      <v-card-subtitle class="title py-0 d-flex">
        <p v-if="p.pay != ''" class="mb-0">${{ p.pay }}</p>
        <p v-if="p.startDate != ''" class="mb-0 ml-10">
          Start Date {{ p.startDate }}
        </p>
      </v-card-subtitle>

      <v-btn
        color="primary"
        absolute
        outlined
        rounded
        top
        right
        :to="`/careers/${p.id}`"
      >
        Apply
      </v-btn>

      <v-card-actions class="my-0 py-0">
        <v-spacer></v-spacer>
        <v-btn icon @click="p.show = !p.show">
          <v-icon>
            {{ p.show ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </v-card-actions>

      <v-expand-transition>
        <div v-show="p.show == true">
          <v-divider></v-divider>
          <v-card-text v-html="p.description" />
          <v-btn
            color="primary"
            outlined
            rounded
            class="mb-4 ml-4"
            :to="`/careers/${p.id}`"
          >
            Apply
          </v-btn>
        </div>
      </v-expand-transition>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { positionsCollection } from "../../firebase/credentials";
export default {
  computed: {
    ...mapState(["isAuthenticated"]),
  },
  props: {
    careers: {
      type: Array,
      default: () => [],
    },
  },
  methods: {
    async remove(id) {
      try {
        await positionsCollection.doc(id).delete();
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
